import { useState, useEffect } from 'react';
import { format } from '../../../../utils/CustomPackages/dateUtils';

function CustomTime() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const getTimeZone = () => {
    return Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
      .formatToParts(currentTime)
      .find(part => part.type === 'timeZoneName')?.value || '';
  };

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '12px', padding: '12px', display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center' }}>
      <span style={{ fontSize: '20px', color: '#1B4965', marginTop: '14px', marginRight: '5px' }}>
        &#128336;
      </span>
      <div style={{ flex: 1 }}>
        <p style={{ fontSize: '12px', color: '#757575', margin: 0, fontWeight: 'bold' }}>Current time</p>
        <p style={{ fontSize: '20px', fontWeight: 'bold', color: '#1B4965', margin: 0 }}>
          {format(currentTime, 'dd/MM/yyyy')}, {format(currentTime, 'hh:mm a')} ({getTimeZone()})
        </p>
      </div>
    </div>
  );
}

export default CustomTime;
