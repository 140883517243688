import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { Box, Grid, Typography, Paper, Select, MenuItem, FormControl, InputLabel, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Popover } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { CheckCircle, Info } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import './UserAttendance.css'
import User from '../../../assets/NavbarImages/user.svg'
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { selectToken, selectUser } from '../../../Redux/selector';
import TimePeriod from '../../../Components/UiUxComponents/CustomComponents/TimePeriod';
import { getAllUserAttendanceData } from '../../../utils/Services/attendanceServices';
import { eachDayOfInterval, endOfWeek, format, isSaturday, isSunday, normalizeDate, startOfWeek } from '../../../utils/CustomPackages/dateUtils';
// import { CancelOutlined, CheckCircleOutline, Edit } from '@mui/icons-material';

const UserAttendance = () => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [users, setUsers] = useState([]);
  const [userAttendanceData, setUserAttendanceData] = useState([]);
  const [startDate, setStartDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [endDate, setEndDate] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }));
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const { subscriberId } = useParams();

  // To get the default user attendance data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const formattedData = {
          weekStart: startDate,
          weekEnd: endDate,
          userId: selectedEmployee?._id
        };
        const response = await getAllUserAttendanceData(token, subscriberId, formattedData);
        setUsers(response?.data?.users || []);
        setUserAttendanceData(response?.data?.attendance || [])
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed ',
          text: 'Failed to fetch user attendance',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } finally {
        setLoading(false);
      }
    };

    if (selectedEmployee) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee, startDate, endDate, subscriberId]);

  // To get the selected user attendance data
  useEffect(() => {
    const initializeSelectedEmployee = async () => {
      setLoading(true);
      try {
        const formattedData = {
          weekStart: startDate,
          weekEnd: endDate,
        };
        const response = await getAllUserAttendanceData(token, subscriberId, formattedData);
        const fetchedUsers = response?.data?.users || [];
        setUsers(fetchedUsers);
        setUserAttendanceData(response?.data?.attendance || [])

        if (user?._id && fetchedUsers.some(u => u._id === user._id)) {
          setSelectedEmployee(fetchedUsers.find(u => u._id === user._id));
        }
      } catch (error) {
        console.error('Error fetching attendance data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Failed ',
          text: 'Failed to fetch user attendance',
          confirmButtonColor: '#1B4965',
          customClass: {
            container: 'swal-container',
          }
        });
      } finally {
        setLoading(false);
      }
    };
    initializeSelectedEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // Handle employee change in dropdown change
  const handleEmployeeChange = (event) => {
    const selectedUser = users.find(u => u._id === event.target.value);
    setSelectedEmployee(selectedUser);
  };

  // Handle date change 
  const handleDateRangeChange = (start, end) => {
    if (start) setStartDate(start);
    if (end) setEndDate(end);
  };

  // To Display User Image
  const userImageUrl = selectedEmployee?.profilePic?.location ? selectedEmployee.profilePic.location : User;

  // To Display Weekly Dates
  const getWeekDates = () => {
    const start = startDate
    const end = endDate
    return eachDayOfInterval(start, end, "weekdates");
  };

  // Calculate average gross hours for the current week or month
  function getAverageTime(array) {
    let totalMinutes = 0;

    array.forEach(dateTime => {
      const localTime = new Date(dateTime).toLocaleTimeString('en-GB', {
        hour12: false
      });

      const [hours, minutes, seconds] = localTime.split(':').map(Number);
      totalMinutes += hours * 60 + minutes + seconds / 60;
    });

    const averageMinutes = totalMinutes / array.length;
    const avgHours = Math.floor(averageMinutes / 60);
    const avgMinutes = Math.floor(averageMinutes % 60);
    const avgSeconds = Math.round((averageMinutes % 1) * 60);

    return `${String(avgHours).padStart(2, '0')}:${String(avgMinutes).padStart(2, '0')}:${String(avgSeconds).padStart(2, '0')}`;
  }

  // To Calculate Weekly Clockin Average
  const calculateClockInAverage = () => {
    const weekRecords = generateRecords().filter(record => !record.isWeekend && record.record?.clockInTime);

    if (weekRecords.length === 0) {
      return "0:00";
    }
    const clockInTimes = weekRecords.map(record => (record.record.clockInTime));
    const averageClockInTime = getAverageTime(clockInTimes)

    return averageClockInTime;
  };

  // To Calculate Weekly ClockOut Average
  const calculateClockOutAverage = () => {
    const weekRecords = generateRecords().filter(record => !record.isWeekend && record.record?.clockOutTime);
    if (weekRecords.length === 0) {
      return "0:00";
    }
    const clockOutTimes = weekRecords.map(record => (record.record.clockOutTime));
    const averageClockOutTime = getAverageTime(clockOutTimes)
    return averageClockOutTime;
  };

  // To Display Data in table
  const generateRecords = () => {
    const dates = getWeekDates();
    return dates.map(date => {
      const isWeekend = isSaturday(date) || isSunday(date);
      const record = userAttendanceData.find(r => {
        const clockInDate = normalizeDate(new Date(r.clockInTime));
        const comparisonDate = normalizeDate(new Date(date));
        return format(clockInDate, 'yyyy-MM-dd') === format(comparisonDate, 'yyyy-MM-dd');
      });
      return { date, record, isWeekend, tag: isWeekend ? 'W-OFF' : null };
    });
  };
  const filteredRecords = generateRecords();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mouse-over-popover' : undefined;

  return (
    <Box sx={{ padding: 2, background: '#f1f1f1', height: '100%', maxHeight: '85vh', overflow: 'auto' }} className="custom-scrollbar">
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper sx={{ padding: '20px', background: '#f9f9f9' }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img src={userImageUrl} alt="" style={{ height: '100px', width: '100px', borderRadius: '50%', objectFit: 'fill' }} />
              <Typography variant="h6" sx={{ marginTop: 2 }}>{selectedEmployee?.fullName}</Typography>
              <Typography variant="subtitle2" color="textSecondary" sx={{ marginBottom: 1 }}>{selectedEmployee?.designation?.name}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', background: '#f0f0f0', borderRadius: '8px', padding: '10px 16px', marginTop: 2, width: '100%', justifyContent: 'center' }}>
                <AccessTimeIcon sx={{ fontSize: 28, color: 'textSecondary', marginRight: 1 }} />
                <Typography variant="h5" sx={{ fontWeight: 600 }}>00:00:00</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1, color: 'textSecondary' }}>Total hours</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 2, width: '100%' }}>
                <Box>
                  <Box sx={{ height: '4px', width: '30px', backgroundColor: '#FFB300', }}></Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{calculateClockInAverage()}</Typography>
                  <Typography variant="body2" sx={{ color: 'textSecondary' }}>Avg Clock-In</Typography>
                </Box>
                <Box>
                  <Box sx={{ height: '4px', width: '30px', backgroundColor: '#FFB300' }}></Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>0:00 hrs</Typography>
                  <Typography variant="body2" sx={{ color: 'textSecondary' }}>Overtime</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 2, width: '100%' }}>
                <Box>
                  <Box sx={{ height: '4px', width: '30px', backgroundColor: '#FFB300' }}></Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{calculateClockOutAverage()}</Typography>
                  <Typography variant="body2" sx={{ color: 'textSecondary' }}>Avg Clock-Out</Typography>
                </Box>
                <Box>
                  <Box sx={{ height: '4px', width: '30px', backgroundColor: '#FFB300' }}></Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>0:00 hrs</Typography>
                  <Typography variant="body2" sx={{ color: 'textSecondary' }}>Leave</Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <Paper sx={{ padding: '20px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <TimePeriod startDate={startDate} endDate={endDate} onDateRangeChange={handleDateRangeChange} />
              <FormControl margin="normal" size="small" sx={{ minWidth: 200 }}>
                <InputLabel id="employee-select-label">Select Employee</InputLabel>
                <Select labelId="employee-select-label" id="employee-select" value={selectedEmployee?._id || ''} label="Select Employee" onChange={handleEmployeeChange}>
                  {users.map((user) => (
                    <MenuItem key={user._id} value={user._id}>{user.fullName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6">Hour breakdown: 264.00 hrs</Typography>
              <Box sx={{ width: '100%', backgroundColor: '#e0e0e0', height: '10px', borderRadius: '5px', marginTop: '10px' }}>
                <Box sx={{ width: '50%', backgroundColor: 'green', height: '100%', borderRadius: '5px' }}></Box>
              </Box>
              <Typography variant="body2" color="textSecondary">Approved: 132 hrs | Rejected: 40 hrs | Pending: 10 hrs</Typography>
            </Box> */}
            {/* <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary">Add Time off</Button>
              <Box>
                <Button variant="contained" color="secondary" sx={{ marginRight: 1 }}>Reject All</Button>
                <Button variant="contained" color="success">Approve All</Button>
              </Box>
            </Box> */}
            <TableContainer sx={{ marginTop: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Clock-In</TableCell>
                    <TableCell>Clock-Out</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Effective Hours</TableCell>
                    <TableCell>Gross Hours</TableCell>
                    <TableCell>Arrival</TableCell>
                    <TableCell>Log</TableCell>
                    {/* <TableCell>Note</TableCell>
                    <TableCell>Approval</TableCell>
                    <TableCell>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                    <TableCell>1st Jun 2022</TableCell>
                    <TableCell>09:00 AM</TableCell>
                    <TableCell>09:00 PM</TableCell>
                    <TableCell>1:00 hrs</TableCell>
                    <TableCell>8.00 hrs</TableCell>
                    <TableCell>4.00 hrs</TableCell>
                    <TableCell>N/A</TableCell>
                    <TableCell>
                      <CheckCircleOutline color="success" />
                      <CancelOutlined color="error" sx={{ marginLeft: 1 }} />
                    </TableCell>
                    <TableCell>
                      <IconButton><Edit /></IconButton>
                    </TableCell>
                  </TableRow> */}
                  {filteredRecords?.map((item, index) => {
                    const clockIn = item?.record && item.record.clockInTime
                      ? format(new Date(item.record.clockInTime), 'hh:mm a')
                      : '-';
                    let clockOut = item?.record && item.record.clockOutTime
                      ? format(new Date(item.record.clockOutTime), 'hh:mm a')
                      : '-';
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body2">{format(item.date, 'MMM dd, EEE')}</Typography>
                          {item.tag && (
                            <Button variant="contained" size="small"
                              sx={{
                                marginRight: 0.5,
                                marginTop: 0.5,
                                backgroundColor: '#FF6347',
                                '&:hover': {
                                  backgroundColor: '#FF6347',
                                },
                              }}
                            >
                              {item.tag}
                            </Button>
                          )}
                          {item.record && item.record.place && !item.tag && (
                            <Button variant="contained" size="small"
                              sx={{
                                marginRight: 0.5,
                                marginTop: 0.5,
                                backgroundColor: '#1B4965',
                                '&:hover': {
                                  backgroundColor: '#1B4965',
                                },
                              }}
                            >
                              {item.record.place === 'Office Clock-in' ? 'Office' : item.record.place === 'Work From Home' ? 'WFH' : 'Other'}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>{clockIn}</TableCell>
                        <TableCell>{clockOut}</TableCell>
                        <TableCell>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {item?.record?.location ? (
                              <>
                                <LocationOnIcon onMouseEnter={handlePopoverOpen} sx={{ marginRight: 1, color: '#1B4965' }} />
                                <Popover id={id} open={open} anchorEl={anchorEl} onClose={handlePopoverClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} sx={{ boxShadow: 3, borderRadius: 1, }} disableRestoreFocus>
                                  <Box sx={{ p: 0, width: 300, overflow: 'hidden', borderRadius: 1 }} onMouseLeave={handlePopoverClose}>
                                    <MapContainer center={[item.record.location.latitude, item.record.location.longitude]} zoom={15} style={{ width: '100%', height: '200px', borderRadius: '8px' }}>
                                      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                                      <Marker
                                        position={[item.record.location.latitude, item.record.location.longitude]}
                                        icon={new L.Icon({
                                          iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
                                          iconSize: [25, 41],
                                          iconAnchor: [12, 41],
                                          popupAnchor: [1, -34],
                                        })}
                                      >
                                        <Popup>
                                          Latitude: {item.record.location.latitude}<br />
                                          Longitude: {item.record.location.longitude}
                                        </Popup>
                                      </Marker>
                                    </MapContainer>
                                  </Box>
                                </Popover>
                              </>
                            ) : (
                              <span>No Location</span>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{item.record && item.record.effectiveHours ? `${item.record.effectiveHours} hrs` : '-'}</TableCell>
                        <TableCell>{item.record && item.record.grossHours ? `${item.record.grossHours} hrs` : '-'}</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>
                          {item.record && item.record.clockInTime && !item.record.clockOutTime ? (
                            <Info color="warning" />
                          ) : (
                            item.record ? <CheckCircle color="success" /> : '-'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      {(loading) && (
        <Loader />
      )}
    </Box>
  );
};

export default UserAttendance;