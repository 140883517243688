import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from "sweetalert2";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import { selectToken } from '../../../Redux/selector';
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { getCompanyAttendanceStatistics } from '../../../utils/Services/companyAdminDashboardServices';

ChartJS.register(ArcElement, Tooltip, Legend);

function CompanyAdminDashboard() {
  const [attendanceStatistics, setAttendanceStatistics] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector(selectToken);
  const { subscriberId } = useParams();

  // To get Company Attendance Statistics
  const fetchCompanyAttendanceStatistics = async () => {
    setLoading(true);
    try {
      const currentDateTime = new Date();
      const data = { currentDateTime };

      const response = await getCompanyAttendanceStatistics(token, subscriberId, data);
      setAttendanceStatistics(response?.data)
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch attendance statistics',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCompanyAttendanceStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doughnutData = {
    labels: ['Clock In', 'Clock Out', 'Not Clock In', 'On Leave', 'Weekly Off', 'Holiday',],
    datasets: [
      {
        data: [attendanceStatistics?.clockInCount || 0, attendanceStatistics?.clockOutCount || 0, attendanceStatistics?.notClockedInCount || 0, 0, 0, 0],
        backgroundColor: ['#4CAF50', '#FF7043', '#29B6F6', '#FFEB3B', '#FFCDD2', '#FFC107'],
        hoverOffset: 4,
      },
    ],
  };

  const doughnutOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const centerTextPlugin = {
    id: 'centerText',
    beforeDraw: (chart) => {
      const { ctx, chartArea: { width, height } } = chart;
      ctx.save();
      ctx.font = 'bold 16px Arial';
      ctx.fillStyle = '#666';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('Total Employees', width / 2, height / 2 - 10);
      ctx.font = 'bold 24px Arial';
      ctx.fillText(attendanceStatistics?.totalEmployees || '0', width / 2, height / 2 + 20);
    },
  };

  return (
    <span>
      <Box sx={{ padding: 2, maxHeight: '85vh', overflow: 'auto' }}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ padding: 1, flexGrow: 1, backgroundColor: '#F5F5F5', }}>
              <CardContent>
                <Typography variant="h6" align="flex-start" gutterBottom>Statistics</Typography>
                {loading || !attendanceStatistics ? (
                  <Loader />
                ) : (
                  <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                    <Doughnut data={doughnutData} options={doughnutOptions} plugins={[centerTextPlugin]} />
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {(loading) && (<Loader />)}
    </span >
  );
}

export default CompanyAdminDashboard;
