import { useState } from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import VisibilityIcon from '@mui/icons-material/Visibility';

import './LeaveTimeoff.css';
import LeaveTimeoffPagination from '../../../Components/UserComponents/LeaveTimeoff/LeaveTImeoffPagination/LeaveTimeoffPagination'
import ApplyLeave from '../../../Components/UserComponents/LeaveTimeoff/ApplyLeave/ApplyLeave';

function LeaveTimeoff() {

  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const statistics = [
    { title: 'Earned', value: 10, maxValue: 10, color: '#007bff' },
    { title: 'Casual', value: 10, maxValue: 13, color: '#28a745' },
    { title: 'Sick', value: 8, maxValue: 10, color: '#ffc107' },
    { title: 'Paternity', value: 14, maxValue: 20, color: '#6f42c1' },
    { title: 'Sabbatical', value: 11, maxValue: 17, color: '#fd7e14' },
  ];

  const data = [
    { id: 1, manager: "Stephen Universe", leaveType: "Paternity Leave", startDate: "2024-01-11", endDate: "2024-01-11", status: "Pending" },
    { id: 2, manager: "Stephen Universe", leaveType: "Sabbatical Leave", startDate: "2024-04-18", endDate: "2024-04-19", status: "Pending" },
    { id: 3, manager: "Stephen Universe", leaveType: "Paternity Leave", startDate: "2024-03-20", endDate: "2024-03-20", status: "Pending" },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = data.slice(startIndex, startIndex + itemsPerPage);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div className='main-container' style={{ backgroundColor: '#f1f1f1' }}>
      <div className="header-container">
        <h1 className="header-title">Leave Availability</h1>
        <button className="apply-button" onClick={handleOpenModal}>Apply Leave</button>
      </div>
      <div className="statistics-container">
        {statistics.map((stat, index) => (
          <div key={index} className="stat-card">
            <div className='stat-container'>
              <h4 className="stat-remaining">{stat.title} Leave</h4>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <div className="progress-wrapper">
                  <CircularProgressbar
                    value={stat.value}
                    maxValue={stat.maxValue}
                    text={`‎${stat.value}/${stat.maxValue}`}
                    styles={buildStyles({
                      textSize: '24px',
                      pathColor: stat.color,
                      textColor: '#212529',
                      trailColor: '#f5f5f5',
                    })}
                  />
                </div>
                <div className="stat-details-wrapper">
                  <p className="stat-details">Available - {stat.value}</p>
                  <p className="stat-details">Used - {stat.maxValue - stat.value}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="main-content-container" style={{ display: 'flex', gap: '20px' }}>
        <div className="leave-approval" style={{ flex: 1 }}>
          <h2 className="section-title">Leave Approval</h2>
          <table className="approval-table">
            <thead>
              <tr>
                <th>Manager</th>
                <th>Leave Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((row, index) => (
                <tr key={index}>
                  <td>{row.manager}</td>
                  <td>{row.leaveType}</td>
                  <td>{row.startDate}</td>
                  <td>{row.endDate}</td>
                  <td>{row.status}</td>
                  <td><VisibilityIcon className="table-action-icon" /></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <LeaveTimeoffPagination
              totalItems={data.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <div className="leave-track" style={{ flex: 1 }}>
          <h2 className="section-title">Your Leave Track</h2>
          <div className="leave-chart">
            {/* Add your chart implementation here (e.g., using Chart.js or Recharts) */}
          </div>
        </div>
      </div>
      <ApplyLeave openModal={openModal} handleCloseModal={handleCloseModal}/>
    </div>
  );
}

export default LeaveTimeoff;
