import React, { useEffect, useState } from 'react';

import { ChromePicker } from 'react-color';
import { Box, Button, Modal, Typography, Tabs, Tab, Divider, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, FormControlLabel } from '@mui/material';

function ViewLeaveType({ openModal, handleCloseModal, leaveTypeData }) {
    const [tabIndex, setTabIndex] = useState(0);
    const [leaveTypeName, setLeaveTypeName] = useState('');
    const [description, setDescription] = useState('');
    const [leaveTypeColor, setLeaveTypeColor] = useState('#ffffff');
    const [leaveConfig, setLeaveConfig] = useState({
        maxLeaves: 10,
        monthlyLeaves: 1,
        monthlyAddition: 1,
        addLeavesStartMonth: 'Feb',
        excludeMonths: ['Jan', 'Dec'],
        carryForward: true,
        applicationNotice: 3,
        leaveOpenMonth: 'Jan',
        type: 'Casual Leave',
    });

    useEffect(() => {
        if (leaveTypeData) {
            setLeaveTypeName(leaveTypeData.name || '');
            setDescription(leaveTypeData.description || '');
            setLeaveTypeColor(leaveTypeData.color || '#ffffff');
        }
    }, [leaveTypeData]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleSave = () => {
        const updatedData = {
            name: leaveTypeName,
            description,
            color: leaveTypeColor,
        };
        handleCloseModal();
    };

    return (
        <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Box sx={{ width: '30%', height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative', left: 0, transition: 'transform 0.3s ease-in-out', transform: openModal ? 'translateX(0)' : 'translateX(-100%)' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '15px' }}>
                    <Typography variant="h6" sx={{ color: '#1B4965' }}>Update / Configure Leave Type</Typography>
                    <Button onClick={handleCloseModal} variant="contained" sx={{ backgroundColor: '#1B4965', color: 'white', '&:hover': { backgroundColor: '#1B4965' }, fontSize: '12px' }}>Back</Button>
                </Box>

                <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                    <Tab label="Info" />
                    <Tab label="Configure" />
                </Tabs>
                <Divider sx={{ marginTop: '10px', marginBottom: '20px' }} />

                <Box sx={{ marginTop: '20px' }}>
                    {tabIndex === 0 && (
                        <Box>
                            <TextField label="Leave Type Name" variant="outlined" fullWidth required
                                sx={{ marginBottom: 2 }}
                                value={leaveTypeName}
                                onChange={(e) => setLeaveTypeName(e.target.value)}
                            />
                            <TextField label="Description" variant="outlined" fullWidth required multiline rows={4}
                                sx={{ marginBottom: 2 }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <Typography sx={{ marginBottom: 1 }}>Leave Type Color</Typography>
                            <ChromePicker color={leaveTypeColor}
                                onChangeComplete={(color) => setLeaveTypeColor(color.hex)}
                                disableAlpha={true}
                                style={{ marginBottom: '20px' }}
                            />
                            <Button variant="contained"
                                sx={{ backgroundColor: '#1B4965', color: 'white', '&:hover': { backgroundColor: '#1B4965' }, marginTop: 2 }}
                                onClick={handleSave}
                            >Update
                            </Button>
                        </Box>
                    )}

                    {tabIndex === 1 && (
                        <Box>
                            <Typography variant="h6" sx={{ marginBottom: '10px' }}>Configure Leave Type</Typography>
                            <TextField label="Maximum Leaves per Year" type="number" variant="outlined" fullWidth required
                                sx={{ mb: 2 }}
                                value={leaveConfig.maxLeaves}
                                onChange={(e) => setLeaveConfig({ ...leaveConfig, maxLeaves: e.target.value })}
                            />
                            <TextField label="No of Leaves should take in a Month" type="number" variant="outlined" fullWidth
                                sx={{ mb: 2 }}
                                required
                                value={leaveConfig.monthlyLeaves}
                                onChange={(e) => setLeaveConfig({ ...leaveConfig, monthlyLeaves: e.target.value })}
                            />

                            <TextField label="No of Leaves to Add Every Month" type="number" variant="outlined" fullWidth
                                sx={{ mb: 2 }}
                                required
                                value={leaveConfig.monthlyAddition}
                                onChange={(e) => setLeaveConfig({ ...leaveConfig, monthlyAddition: e.target.value })}
                            />

                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <InputLabel>Exclude Months</InputLabel>
                                <Select multiple
                                    value={leaveConfig.excludeMonths}
                                    onChange={(e) => setLeaveConfig({ ...leaveConfig, excludeMonths: e.target.value })}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => (
                                        <MenuItem key={month} value={month}>
                                            <Checkbox checked={leaveConfig.excludeMonths.includes(month)} />
                                            <ListItemText primary={month} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={leaveConfig.carryForward}
                                        onChange={(e) => setLeaveConfig({ ...leaveConfig, carryForward: e.target.checked })}
                                    />
                                }
                                label="Allow Carry Forward"
                                sx={{ mb: 2 }}
                            />

                            <TextField
                                label="Application Notice Period (days)"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={leaveConfig.applicationNotice}
                                onChange={(e) => setLeaveConfig({ ...leaveConfig, applicationNotice: e.target.value })}
                            />

                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    )
}

export default ViewLeaveType;
