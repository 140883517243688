import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';
import ExcelJS from 'exceljs';
import DeleteIcon from '@mui/icons-material/Delete';

import { selectToken, selectUser } from '../../../Redux/selector';
import Loader from '../../../Components/UiUxComponents/Loader/Loader';
import { getuserDetailsByTabName } from '../../../utils/Services/userServices';

const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/soom-hub`;

function Modal({ show, onClose, children, date }) {
  if (!show) return null;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 }}>
      <div style={{ width: '90%', maxHeight: '80%', backgroundColor: '#fff', padding: '20px', borderRadius: '8px', overflowY: 'auto', }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{ fontWeight: 'bold', fontSize: '22px' }}>Daily Report: {date}</div>
          <button onClick={onClose} style={{ padding: '10px 20px', cursor: 'pointer', backgroundColor: '#1B4965', color: 'white', fontSize: '16px', borderRadius: '8px', border: 'none' }}>Close</button>
        </div>
        {children}
      </div>
    </div>
  );
}

const DailyReports = () => {
  // eslint-disable-next-line no-unused-vars
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tasks, setTasks] = useState([{ id: 1, description: '', hours: '', minutes: '', company: '', status: '' }]);
  const [officeInformationDetails, setOfficeInformatinDetails] = useState()
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const { userId } = useParams();
  const tabName = "companyDetails"

  const employeeName = user?.fullName
  console.log("officeInformationDetails", officeInformationDetails)
  const employeeCode = officeInformationDetails?.employeeCode
  console.log("employeeCode", employeeCode)

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

  // To get user office details
  const fetchOfficeDetails = async () => {
    setLoading(true);
    try {
      const response = await getuserDetailsByTabName(token, tabName, userId);
      setOfficeInformatinDetails(response?.data[0])
    } catch (err) {
      console.error('Error fetching user personal details:', err);
      Swal.fire({
        icon: 'error',
        title: 'Failed ',
        text: 'Failed to fetch user company details',
        confirmButtonColor: '#1B4965',
        customClass: {
          container: 'swal-container',
        }
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOfficeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To Get Drafted Data
  useEffect(() => {
    if (!employeeCode) return;
    const fetchDraftedData = async () => {

      if (!formattedDate) {
        return; 
      }

      setLoading(true);
      try {
        const response = await axios.get(`${backEndUrl}/get-drafted-daily-report/${employeeCode}`, {
          params: { formattedDate },
        });

        setTasks(response?.data?.report?.tasks);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          const errorMessage = err.response.data?.message || 'No tasks found for today.';
          Swal.fire(
            'No Drafted Data Found',
            errorMessage,
            'info'
          );
        } else {
          console.error('Error fetching drafted data:', err);
          Swal.fire('Error', 'There was an error getting drafted data. Please try again.', 'error');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDraftedData();
  }, [formattedDate, employeeCode]);

  const handleAddRow = () => {
    const lastTask = tasks[tasks.length - 1];
    const isLastTaskFilled = lastTask.description !== '' && lastTask.company !== '' && lastTask.status !== '';

    if (isLastTaskFilled) {
      setTasks([...tasks, { id: tasks.length + 1, description: '', hours: '', minutes: '', company: '', status: '' }]);
    } else {
      Swal.fire({

        text: 'Please fill all fields in the current task before adding a new one.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedTasks = [...tasks];
    const numericValue = value.replace(/\D/g, '');

    if (field === 'hours') {
      updatedTasks[index][field] = Math.max(0, Math.min(12, Number(numericValue) || 0));
    } else if (field === 'minutes') {
      updatedTasks[index][field] = Math.max(0, Math.min(60, Number(numericValue) || 0));
    } else {
      updatedTasks[index][field] = value;
    }

    setTasks(updatedTasks);
  };

  const handleBlur = (index, field) => {
    const updatedTasks = [...tasks];
    const value = updatedTasks[index][field].toString();

    if (field === 'hours' || field === 'minutes') {
      updatedTasks[index][field] = value.padStart(2, '0');
    }

    setTasks(updatedTasks);
  };

  const handleDeleteRow = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
  };

  const handlePreview = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {

    if (!employeeCode) {
      Swal.fire({
        title: 'Error',
        text: 'You don’t have an employee code. Please contact your administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }

    const reportData = {
      userId: userId,
      employeeId: employeeCode,
      employeeName: employeeName,
      tasks: tasks,
      formattedDate: formattedDate,
    };

    let DesignaionName = 'Full Stack Developer'
    Swal.fire({
      title: 'Confirm Submission',
      text: 'Are you sure you want to submit the report?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true)
        axios.post(`${backEndUrl}/submit-daily-report/${employeeCode}`, reportData)
          .then(async (response) => {
            setLoading(false)
            Swal.fire({
              title: 'Submitted',
              text: 'Your report has been submitted successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
            });

            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Daily Report');

            const titleRow = [["Daily Work Status Report - " + formattedDate]];
            worksheet.addRow(titleRow[0]);
            worksheet.mergeCells('A1:I1');

            worksheet.getCell('A1').alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getRow(1).font = { bold: true, size: 14, color: { argb: 'FFFFFF' } };
            worksheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3A5B84' } };

            const employeeRow = [["Employee Name:", employeeName, "Designation:", DesignaionName]];
            worksheet.addRow(employeeRow[0]);

            const borderStyle = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('A2').font = { bold: true };
            worksheet.getCell('A2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8CBAD' } };
            worksheet.getCell('A2').border = borderStyle;

            worksheet.mergeCells('B2:D2');
            worksheet.getCell('B2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8CBAD' } };
            worksheet.getCell('B2').border = borderStyle;
            worksheet.getCell('B2').alignment = { horizontal: 'center', vertical: 'middle' };

            worksheet.getCell('E2').font = { bold: true };
            worksheet.getCell('E2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8CBAD' } };
            worksheet.getCell('E2').border = borderStyle;

            worksheet.mergeCells('F2:I2');
            worksheet.getCell('F2').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8CBAD' } };
            worksheet.getCell('F2').border = borderStyle;
            worksheet.getCell('F2').alignment = { horizontal: 'center', vertical: 'middle' };

            worksheet.getRow(3).font = { bold: true };
            worksheet.getRow(3).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F0F0F0' } };

            const headers = ["Task No", "Task Description", "", "", "", "Task Status", "Task Hours", "Company"];
            worksheet.addRow(headers);

            tasks.forEach((task, index) => {
              const hours = task.hours || '00';
              const minutes = task.minutes || '00';
              worksheet.addRow([
                index + 1,
                task.description,
                '',
                '',
                '',
                task.status,
                `${hours} hrs ${minutes} mins`,
                task.company,
              ]);
            });

            worksheet.getRow(4).font = { bold: true };
            worksheet.getRow(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BDD7EE' } };

            for (let col = 1; col <= 9; col++) {
              const cell = worksheet.getCell(4, col);
              cell.border = borderStyle;
              cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BDD7EE' } };
            }

            worksheet.mergeCells('B4:E4');
            worksheet.mergeCells('F4:F4');
            worksheet.mergeCells('H4:I4');

            worksheet.getCell('B4').alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getCell('F4').alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getCell('H4').alignment = { horizontal: 'center', vertical: 'middle' };

            tasks.forEach((task, index) => {
              worksheet.mergeCells(`B${index + 5}:E${index + 5}`);
              worksheet.getCell(`B${index + 5}`).alignment = { horizontal: 'center', vertical: 'middle' };

              worksheet.mergeCells(`H${index + 5}:I${index + 5}`);
              worksheet.getCell(`H${index + 5}`).alignment = { horizontal: 'center', vertical: 'middle' };
            });

            for (let i = 5; i <= worksheet.rowCount; i++) {
              for (let col = 1; col <= 9; col++) {
                const cell = worksheet.getCell(i, col);
                cell.border = borderStyle;
                if (col === 1 || col === 5 || col === 7 || col === 8) {
                  cell.alignment = { horizontal: 'center', vertical: 'middle' };
                }
              }
            }

            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${employeeName}_Daily_Report_${formattedDate}.xlsx`;
            link.click();

            setShowModal(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error submitting report:", error);
            if (error.response && error.response.status === 400) {
              Swal.fire('Warning', 'You already submitted your daily report.', 'warning');
            } else {
              console.error("Error submitting attendance", error);
              Swal.fire('Error', 'There was an error submitting the report. Please try again.', 'error');
            }
          });
      }
    });
  };

  const saveToDraft = async () => {
    if (!employeeCode) {
      Swal.fire({
        title: 'Error',
        text: 'You don’t have an employee code. Please contact your administrator.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (!employeeName || tasks.length === 0 || !formattedDate) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill in all required fields before saving.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }

    const incompleteTask = tasks.some(task =>
      !task.description || task.hours == null || task.minutes == null || !task.company || !task.status
    );

    if (incompleteTask) {
      Swal.fire({
        title: 'Error',
        text: 'Please fill in all required fields.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }

    const reportData = {
      userId: userId,
      employeeId: employeeCode,
      employeeName: employeeName,
      tasks: tasks,
      formattedDate: formattedDate,
    };
    setLoading(true);
    try {
      await axios.post(`${backEndUrl}/save-to-draft/${employeeCode}`, reportData);
      Swal.fire({
        title: 'Success',
        text: 'Daily Report saved to draft successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error("Error saving report:", error);
      if (error.response && error.response.status === 400) {
        Swal.fire('Warning', 'You already submitted your daily report.', 'warning');
      } else {
        console.error("Error submitting attendance", error);
        Swal.fire('Error', 'There was an error submitting the report. Please try again.', 'error');
      }
    } finally {
      setLoading(false)
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '0px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '22px', color: '#1B4965' }}>Daily Report: {formattedDate}</div>
        </div>
        <div>
          <button onClick={saveToDraft} style={{ padding: '10px 20px', backgroundColor: '#1B4965', color: 'white', border: 'none', cursor: 'pointer', borderRadius: '4px', marginRight: '10px' }}>
            Save to Draft
          </button>
          <button onClick={handleAddRow} style={{ padding: '10px 20px', backgroundColor: '#1B4965', color: 'white', border: 'none', cursor: 'pointer', borderRadius: '4px' }}>
            Add Task
          </button>
        </div>
      </div>
      <div style={{ padding: '20px', overflowX: 'auto', marginTop: '-20px' }}>
        <table id='tasksTable' style={{ width: '100%', borderCollapse: 'collapse', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
          <thead>
            <tr style={{ backgroundColor: '#1B4965', color: 'white', textAlign: 'center' }}>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '2%' }}>S.No</th>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '60%' }}>Task Description</th>
              <th colSpan="2" style={{ padding: '5px 15px', fontSize: '16px', textAlign: 'center', border: '1px solid white', width: '10%' }}>Duration</th>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '13%' }}>Company</th>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '15%' }}>Status</th>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '15%' }}></th>
            </tr>
            <tr style={{ backgroundColor: '#1B4965', color: 'white', textAlign: 'center' }}>
              <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white' }}>Hours</th>
              <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white' }}>Min</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={task.id} style={{ borderBottom: '1px solid #ddd', textAlign: 'center' }}>
                <td style={{ padding: '5px 5px' }}>{task.id}</td>
                <td style={{ padding: '5px 5px' }}>
                  <textarea placeholder="Task Description" rows={1}
                    value={task.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                    style={{ width: '98%', padding: '8px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc', minHeight: '30px', resize: 'none', overflow: 'hidden' }}
                    onInput={(e) => {
                      e.target.style.height = 'auto';
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                  />
                </td>
                <td style={{ padding: '12px 5px' }}>
                  <input type="text" placeholder="00" maxLength="2" min="0" max="12"
                    value={task.hours}
                    onChange={(e) => handleInputChange(index, 'hours', e.target.value)}
                    onBlur={() => handleBlur(index, 'hours')}
                    style={{ width: '50%', padding: '8px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc', minHeight: '30px', textAlign: 'center' }}
                  />
                </td>
                <td style={{ padding: '12px 5px' }}>
                  <input type="text" placeholder="00" maxLength="2" min="0" max="60"
                    value={task.minutes}
                    onChange={(e) => handleInputChange(index, 'minutes', e.target.value)}
                    onBlur={() => handleBlur(index, 'minutes')}
                    style={{ width: '50%', padding: '8px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc', minHeight: '30px', textAlign: 'center' }}
                  />
                </td>
                <td style={{ padding: '12px 5px' }}>
                  <input type="text" placeholder="Company Name"
                    value={task.company}
                    onChange={(e) => handleInputChange(index, 'company', e.target.value)}
                    style={{ width: '90%', padding: '8px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc', minHeight: '30px' }}
                  />
                </td>
                <td style={{ padding: '12px 5px' }}>
                  <select
                    value={task.status}
                    onChange={(e) => handleInputChange(index, 'status', e.target.value)}
                    style={{ width: '95%', padding: '8px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ccc', height: '40px', }}
                  >
                    <option value="">Select an option</option>
                    <option value="Completed">Completed</option>
                    <option value="In Progress">In Progress</option>
                  </select>
                </td>
                <td>
                  {index > 0 && (
                    <button onClick={() => handleDeleteRow(index)}
                      style={{
                        padding: '5px',
                        backgroundColor: 'transparent',
                        border: 'none', cursor: 'pointer'
                      }}
                    >
                      <DeleteIcon style={{ color: 'red', fontSize: '20px' }} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button onClick={handlePreview} style={{ padding: '10px 20px', backgroundColor: '#1B4965', color: 'white', border: 'none', cursor: 'pointer', borderRadius: '4px', }}>
            Preview
          </button>
        </div>
      </div>
      <Modal show={showModal} onClose={closeModal} date={formattedDate}>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr style={{ backgroundColor: '#1B4965', color: 'white', textAlign: 'center' }}>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '2%' }}>S.No</th>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '60%' }}>Task Description</th>
              <th colSpan="2" style={{ padding: '5px 15px', fontSize: '16px', textAlign: 'center', border: '1px solid white', width: '10%' }}>Duration</th>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '13%' }}>Company</th>
              <th rowSpan="2" style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white', width: '15%' }}>Status</th>
            </tr>
            <tr style={{ backgroundColor: '#1B4965', color: 'white', textAlign: 'center' }}>
              <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white' }}>Hours</th>
              <th style={{ padding: '5px 15px', fontSize: '16px', border: '1px solid white' }}>Min</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={task.id} style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                <td style={{ padding: '12px 5px' }}>{task.id}</td>
                <td style={{ padding: '12px 5px' }}>{task.description}</td>
                <td style={{ padding: '12px 5px' }}>{task.hours || '00'}</td>
                <td style={{ padding: '12px 5px' }}>{task.minutes || '00'}</td>
                <td style={{ padding: '12px 5px' }}>{task.company}</td>
                <td style={{ padding: '12px 5px' }}>{task.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <button onClick={handleSubmit} style={{ padding: '10px 20px', backgroundColor: '#1B4965', color: 'white', border: 'none', cursor: 'pointer', borderRadius: '4px' }}>Submit</button>
        </div>
      </Modal>
      {(loading) && (
        <Loader />
      )}
    </div>
  )
}

export default DailyReports