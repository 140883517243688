import { Box, Button, Modal, TextField, Typography, MenuItem, FormControl, InputLabel, Select } from '@mui/material'
import React, { useState, useEffect } from 'react'

function ApplyLeave({ openModal, handleCloseModal }) {
    const [leaveType, setLeaveType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [noOfDays, setNoOfDays] = useState(0);
    const [contactNumber, setContactNumber] = useState('');
    const [reason, setReason] = useState('');

    useEffect(() => {
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            setNoOfDays(diffDays);
        } else {
            setNoOfDays(0); // Reset when dates are cleared
        }
    }, [startDate, endDate]);
    

    const handleSubmit = () => {
        const leaveData = {
            leaveType,
            startDate,
            endDate,
            noOfDays,
            contactNumber,
            reason
        };
        console.log(leaveData);
        handleCloseModal();
    };

    return (
        <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Box sx={{ width: '30%', height: '100vh', backgroundColor: 'white', boxShadow: 24, p: 4, position: 'relative', left: 0, transition: 'transform 0.3s ease-in-out', transform: openModal ? 'translateX(0)' : 'translateX(-100%)' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '15px' }}>
                    <Typography variant="h6" sx={{ color: '#1B4965' }}>Apply Leave</Typography>
                    <Button onClick={handleCloseModal} variant="contained"
                        sx={{
                            backgroundColor: '#1B4965', color: 'white',
                            '&:hover': {
                                backgroundColor: '#1B4965',
                            },
                            fontSize: '12px'
                        }}
                    >Back
                    </Button>
                </Box>

                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel>Leave Type</InputLabel>
                        <Select
                            value={leaveType}
                            onChange={(e) => setLeaveType(e.target.value)}
                        >
                            <MenuItem value="Casual Leave">Casual Leave</MenuItem>
                            <MenuItem value="Sick Leave">Sick Leave</MenuItem>
                            <MenuItem value="Loss of Pay">Loss of Pay</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="From Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />

                    <TextField
                        label="To Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />

                    <TextField
                        label="No. of Days"
                        type="number"
                        value={noOfDays}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField
                        label="Contact Number"
                        type="tel"
                        value={contactNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                    />

                    <TextField
                        label="Reason"
                        multiline
                        rows={4}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />

                    <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: '#1B4965', color: 'white', '&:hover': { backgroundColor: '#1B4965' } }}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ApplyLeave;