import React, { useState } from "react";

const LeaveTypePagination = ({ totalItems, itemsPerPage, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            onPageChange(page);
        }
    };

    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(startItem + itemsPerPage - 1, totalItems);

    return (
        <div style={styles.paginationContainer}>
            <span style={styles.infoText}>
                {startItem}-{endItem} of {totalItems} items
            </span>
            <button style={styles.pageButton} disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                {"<"}
            </button>
            {[...Array(totalPages)].map((_, index) => (
                <button
                    key={index + 1}
                    style={{
                        ...styles.pageButton,
                        ...(currentPage === index + 1 ? styles.activePage : {}),
                    }}
                    onClick={() => handlePageChange(index + 1)}
                >
                    {index + 1}
                </button>
            ))}
            <button
                style={styles.pageButton}
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
            >
                {">"}
            </button>
        </div>
    );
};

const styles = {
    paginationContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
    },
    infoText: {
        marginRight: "10px",
        fontSize: "16px",
    },
    pageButton: {
        margin: "0 5px",
        padding: "8px 15px",
        cursor: "pointer",
        border: "1px solid #ddd",
        borderRadius: "4px",
        backgroundColor: "#fff",
    },
    activePage: {
        backgroundColor: "#007bff",
        color: "#fff",
        border: "1px solid #007bff",
    },
};

export default LeaveTypePagination;
