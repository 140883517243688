import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedCompanyAdminContainer from '../utils/ProtectedContainers/ProtectedCompanyAdminContainer';
import CompanyAdminDashboard from '../Pages/CompanyAdmin/CompanyAdminDashboard/CompanyAdminDashboard';
import Userslist from '../Pages/CompanyAdmin/UsersList/Userslist';
import UserDetails from '../Pages/CompanyAdmin/UsersList/UsersDetails/UserDetails';
import CompanyConfiguration from '../Pages/CompanyAdmin/CompanyConfiguration/CompanyConfiguration';
import Departments from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/Departments/Departments';
import DepartmentDetails from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/Departments/DepartmentDetails/DepartmentDetails';
import Designations from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/Designation/Designation';
import DesignationDetails from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/Designation/DesigntionDetails/DesignationDetails';
import Location from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/Location/Location';
import LocationDetails from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/Location/LoctionDetails/LocationDetails'
import UserAttendance from '../Pages/CompanyAdmin/UserAttendance/UserAttendance';
import Client from '../Pages/CompanyAdmin/Client/Client';
import ClientDetails from '../Pages/CompanyAdmin/Client/ClientDetails/ClientDetails';
import AssignEmployees from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/AssignEmployees/AssignEmployees';
import ClientCoordinatorDetails from '../Pages/ClientCoordinators/ClientCoordinatorDetails/ClientCoordinatorDetails';
import Shifts from '../Pages/CompanyAdmin/Shifts/Shifts';
import DailyReports from '../Pages/CompanyAdmin/DailyReports/DailyReports'
import LeaveType from '../Components/CompanyAdminComponents/CompanyConfigurationComponents/LeaveManagement/LeaveTypes/LeaveType';

const CompanyAdminRoutes: React.FC = () => (
  <Routes>
    <Route element={<ProtectedCompanyAdminContainer />}>
      <Route path='/:subscriberId' element={<CompanyAdminDashboard />} />
      
      <Route path='/usersList/:subscriberId' element={<Userslist />} />
      <Route path='/user-details/:userId' element={<UserDetails />} />

      <Route path='/clientsList/:subscriberId' element={<Client/>}/>
      <Route path='client-details/:clientId' element={<ClientDetails/>}/>

      <Route path='/shift/:subscriberId' element={<Shifts/>}/>

      <Route path='/user-attendance/:subscriberId' element={<UserAttendance/>}/>

      <Route path='/daily-reports/:subscriberId' element={<DailyReports/>}/>

      <Route path='/configuration/:subscriberId' element={<CompanyConfiguration />} />

      <Route path='/department/:subscriberId' element={<Departments />} />
      <Route path='/view-department/:departmentId' element={<DepartmentDetails />} />

      <Route path='/designation/:subscriberId' element={<Designations />} />
      <Route path='/view-designation/:designationId' element={<DesignationDetails />} />

      <Route path='/location/:subscriberId' element={<Location />} />
      <Route path='/view-location/:locationId' element={<LocationDetails />} />

      <Route path='/assign-employees/:subscriberId' element={<AssignEmployees/>}/>

      <Route path='/leavetype/:subscriberId' element={<LeaveType/>}/>

      <Route path='/coordinator-details/:coordinatorId' element={<ClientCoordinatorDetails/>}/>
    </Route>
  </Routes>
);

export default CompanyAdminRoutes;