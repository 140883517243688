import './Notificationbar.css'

function Notificationbar() {
  return (
    <div className="notificationbar">
      <div className="scroll-text">Upadate Your Details</div>
    </div>
  )
}

export default Notificationbar
