import axios from 'axios';
const backEndUrl = `${process.env.REACT_APP_BACKEND_URL}/api/leaves`;

// To Create a New Leave Type
export const createLeaveTypes = (token, subscriberId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            let headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
            let response = await axios.post(`${backEndUrl}/create-leave-types/${subscriberId}`, data, { headers });
            return resolve(response);
        } catch (err) {
            return reject(err);
        }
    });
};