import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import './CustomCalendar.css';

const CustomCalendar = () => {

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '12px', padding: '20px' }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        headerToolbar={{
          start: 'prev,next',
          center: 'title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        height="600px"
        contentHeight="auto"
        scrollTime="08:00:00"
        dayMaxEvents={true}
      />
    </div>
  );
};

export default CustomCalendar;
