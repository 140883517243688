import React, { useState } from 'react'

import { IoArrowBack } from 'react-icons/io5';

import './LeaveType.css'
import CreateLeaveTypes from './LeaveTypesComponents/CreateLeaveTypes';
import LeaveTypePagination from './LeaveTypesComponents/LeaveTypePagination';
import ViewLeaveType from './LeaveTypesComponents/ViewLeaveType';

function LeaveType() {
  const [openModal, setOpenModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const itemsPerPage = 10;

  const handleBack = () => {
    window.history.back();
  };

  const [leaveTypes, setLeaveTypes] = useState([
    { name: 'Sick Leave', description: 'For medical reasons', color: '#FF6347' },
    { name: 'Casual Leave', description: 'For personal reasons', color: '#FFD700' },
    { name: 'Paid Leave', description: 'For vacation', color: '#32CD32' },
  ]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = leaveTypes.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (leaveType) => {
    setSelectedLeaveType(leaveType);
    setOpenViewModal(true)
  };

  const handleViewCloseModal = () => setOpenViewModal(false);

  return (
    <div className='main-container' style={{ backgroundColor: '#f1f1f1' }}>
      <div className="header-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="back-button" onClick={handleBack}>
            <IoArrowBack />
          </button>
          <h1 className="header-title">Leave Type</h1>
        </div>
        <button className="apply-button" onClick={handleOpenModal}>Create Leave Type</button>
      </div>
      <div className="line-divider"></div>
      <div style={{ marginTop: '20px', padding: '0px 20px' }}>
        <table className="leave-type-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Leave Type Name</th>
              <th>Description</th>
              <th>Leave Type Color</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((leaveType, index) => (
              <tr key={index} onClick={() => handleRowClick(leaveType)} style={{ cursor: 'pointer' }}>
                <td>{index + 1}</td>
                <td>{leaveType.name}</td>
                <td>{leaveType.description}</td>
                <td style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: leaveType.color, borderRadius: '50%', marginRight: '8px', }}></span>
                  {leaveType.color}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <LeaveTypePagination
            totalItems={leaveTypes.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <CreateLeaveTypes openModal={openModal} handleCloseModal={handleCloseModal} />
      <ViewLeaveType openModal={openViewModal} handleCloseModal={handleViewCloseModal} leaveTypeData={selectedLeaveType}/>
    </div>
  )
}

export default LeaveType