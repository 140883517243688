import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Typography, Grid } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
// import ApartmentIcon from '@mui/icons-material/Apartment';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import ImportExportIcon from '@mui/icons-material/ImportExport';

import { selectUser } from '../../../Redux/selector';

function CompanyConfiguration() {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const subscriberId = user?.companyId?._id

    const handleNavigation = (path) => {
        navigate(path);
    };

    const configItems = [
        {
            icon: <GroupIcon style={{ fontSize: 50, color: '#f57c00' }} />,
            title: 'COMPANY SETUP',
            items: [
                { label: 'Departments', onClick: () => handleNavigation(`/subscriber/department/${subscriberId}`) },
                { label: 'Designation', onClick: () => handleNavigation(`/subscriber/designation/${subscriberId}`) },
                { label: 'Branch / Location', onClick: () => handleNavigation(`/subscriber/location/${subscriberId}`) },
            ]
        },
        {
            icon: <EventIcon  style={{ fontSize: 50, color: '#e53935' }} />,
            title: 'LEAVE MANAGEMENT',
            items: [
                { label: 'Leave Types', onClick: () => handleNavigation(`/subscriber/leavetype/${subscriberId}`) },
                { label: 'View / Upload Leave Policy',  },
                // { label: 'Branch / Location', onClick: () => handleNavigation(`/subscriber/location/${subscriberId}`) },
            ]
        },
        // {
        //     icon: <ApartmentIcon style={{ fontSize: 50, color: '#e53935' }} />,
        //     title: 'CLIENT SETUP',
        //     items: [
        //         { label: 'Assign Employees', onClick: () => handleNavigation(`/subscriber/assign-employees/${subscriberId}`) },
        //     ]
        // },
        // {
        //     icon: <AccountBalanceIcon style={{ fontSize: 50, color: '#e53935' }} />,
        //     title: 'CURRENCIES AND TAX',
        //     items: ['Currencies'],
        // },
        // {
        //     icon: <ImportExportIcon style={{ fontSize: 50, color: '#43a047' }} />,
        //     title: 'IMPORT / EXPORT',
        //     items: [
        //         { label: 'Import / Export Data' }
        //     ]
        // }
    ];

    return (
        <div style={{ backgroundColor: '#ccc', height: '100%' }}>
            <Grid container spacing={4} justifyContent="center" style={{ padding: '20px', display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start' }}>
                {configItems.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index} style={{ display: 'flex' }}>
                        <Box flexGrow={1} textAlign="center" p={2} style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {item.icon}
                            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}>
                                {item.title}
                            </Typography>
                            {item.items.map((subItem, subIndex) => (
                                <Typography variant="body2" style={{ marginTop: '5px', cursor: subItem.onClick ? 'pointer' : 'default', transition: 'color 0.3s', color: subItem.onClick ? 'inherit' : 'gray' }}
                                    key={subIndex}
                                    onClick={subItem.onClick}
                                    onMouseEnter={(e) => {
                                        if (subItem.onClick) {
                                            e.target.style.color = '#f57c00';
                                        }
                                    }}
                                    onMouseLeave={(e) => {
                                        if (subItem.onClick) {
                                            e.target.style.color = 'inherit';
                                        }
                                    }}
                                >{subItem.label}
                                </Typography>
                            ))}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default CompanyConfiguration;