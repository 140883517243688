import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { Box, Button, Divider, Modal, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import Loader from '../../../UiUxComponents/Loader/Loader';
import { selectToken } from '../../../../Redux/selector';
import { updateShift, deleteShift } from '../../../../utils/Services/shiftServices'

// Function to convert 12-hour time format to 24-hour format
const convertTo24HourFormat = (time) => {
    const [hours, minutesPart] = time.split(':');
    const minutes = minutesPart.slice(0, 2);
    const period = minutesPart.slice(2).toLowerCase();
    let hours24 = parseInt(hours, 10);
    if (period === 'pm' && hours24 < 12) {
        hours24 += 12;
    }
    if (period === 'am' && hours24 === 12) {
        hours24 = 0;
    }
    return `${hours24.toString().padStart(2, '0')}:${minutes}`;
};

// Function to calculate the duration between start and end times
const calculateDuration = (startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const startDate = new Date(0, 0, 0, startHours, startMinutes);
    const endDate = new Date(0, 0, 0, endHours, endMinutes);

    if (endDate < startDate) {
        endDate.setDate(endDate.getDate() + 1);
    }

    const diff = endDate - startDate;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} hours ${minutes} minutes`;
};

function EditShifts({ open, handleClose, shift }) {
    const [selectedShift, setSelectedShift] = useState(null);
    const [shiftId, setShiftId] = useState('');
    const [shiftTitle, setShiftTitle] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const { subscriberId } = useParams();

    // To get the shift data
    useEffect(() => {
        if (shift) {
            setShiftId(shift?._id)
            setShiftTitle(shift?.shiftTitle || '');
            setStartTime(shift?.startTime || '');
            setEndTime(shift?.endTime || '');
            setNote(shift?.note || '');
        }
    }, [shift]);

    // Handle Shift Selection
    const handleShiftSelection = (shiftTime) => {
        const [start, end] = shiftTime.split(' - ').map(convertTo24HourFormat);

        if (selectedShift === shiftTime) {
            setSelectedShift(null);
            setStartTime('');
            setEndTime('');
        } else {
            if (startTime || endTime) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'This will override the custom shift time with the default shift time.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#1B4965',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, override it!',
                    cancelButtonText: 'Cancel',
                    customClass: {
                        container: 'swal-container',
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        setSelectedShift(shiftTime);
                        setStartTime(start);
                        setEndTime(end);
                    }
                });
            } else {
                setSelectedShift(shiftTime);
                setStartTime(start);
                setEndTime(end);
            }
        }
    };

    // To Reset States
    const resetState = () => {
        setSelectedShift(null);
        setShiftId('')
        setShiftTitle('');
        setStartTime('');
        setEndTime('');
        setNote('');
    };

    // Handle to update states
    const handleUpdateShift = async () => {
        if (!shiftTitle) {
            Swal.fire({
                title: 'Shift Title Required',
                text: 'Please enter a shift title.',
                icon: 'warning',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            return;
        }

        if (!startTime || !endTime) {
            Swal.fire({
                title: 'Shift Time Required',
                text: 'Please select or enter a start and end time.',
                icon: 'warning',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            return;
        }

        const startTime24 = convertTo24HourFormat(startTime);
        const endTime24 = convertTo24HourFormat(endTime);
        const duration = calculateDuration(startTime24, endTime24);

        const data = {
            shiftId,
            shiftTitle,
            startTime: startTime24,
            endTime: endTime24,
            note,
            selectedShift,
            duration
        };

        try {
            setLoading(true);
            await updateShift(token, subscriberId, data);
            Swal.fire({
                icon: 'success',
                title: 'Shift Updated Successfully',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            handleClose();
        } catch (err) {
            console.error('Failed to update shift:', err);
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Failed to Update Shift',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            resetState();
            setLoading(false);
        }
    };

    // Handle to Cancel the form
    const handleCancel = () => {
        resetState();
        handleClose();
    };

    // Handle to Delete the shift
    const handleDeleteShift = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this shift?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#1B4965',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            customClass: {
                container: 'swal-container',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true);
                    await deleteShift(token, shift._id);
                    Swal.fire({
                        icon: 'success',
                        title: 'Shift Deleted Successfully',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                    handleClose();
                } catch (err) {
                    console.error('Failed to delete shift:', err);
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Failed to Delete Shift',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    return (
        <>
            <Modal open={open} onClose={handleCancel} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ width: 400, bgcolor: 'white', p: 4, boxShadow: 24, borderRadius: 2 }}>
                    <Typography variant="h5" sx={{ textAlign: 'center', color: '#1B4965' }}>Update Shift</Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    <TextField label="Shift Title" placeholder="Enter Shift Title" fullWidth sx={{ mb: 2 }} value={shiftTitle} onChange={(e) => setShiftTitle(e.target.value)} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <TextField label="Start Time" type="time" InputLabelProps={{ shrink: true }} sx={{ width: '45%' }} value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                        <TextField label="End Time" type="time" InputLabelProps={{ shrink: true }} sx={{ width: '45%' }} value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                    </Box>
                    <TextField label="Note" placeholder="Enter any additional information" fullWidth multiline rows={3} sx={{ mb: 2 }} value={note} onChange={(e) => setNote(e.target.value)} />
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>Or, choose time from a Shift Template:</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Button sx={{ backgroundColor: '#E3E7F1', color: 'black', width: '48%' }} onClick={() => handleShiftSelection('4:00am - 1:00pm')}>
                                4:00am - 1:00pm {selectedShift === '4:00am - 1:00pm' && <CheckIcon />}
                            </Button>
                            <Button sx={{ backgroundColor: '#FFECB3', color: 'black', width: '48%' }} onClick={() => handleShiftSelection('7:00am - 4:00pm')}>
                                7:00am - 4:00pm {selectedShift === '7:00am - 4:00pm' && <CheckIcon />}
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button sx={{ backgroundColor: '#FFECB3', color: 'black', width: '48%' }} onClick={() => handleShiftSelection('10:00am - 7:00pm')}>
                                10:00am - 7:00pm {selectedShift === '10:00am - 7:00pm' && <CheckIcon />}
                            </Button>
                            <Button sx={{ backgroundColor: '#E3E7F1', color: 'black', width: '48%' }} onClick={() => handleShiftSelection('9:00am - 6:00pm')}>
                                9:00am - 6:00pm {selectedShift === '9:00am - 6:00pm' && <CheckIcon />}
                            </Button>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleDeleteShift} sx={{ backgroundColor: '#B42626', color: 'white', marginRight: '20px' }}>Delete Shift</Button>
                            <Button onClick={handleCancel} variant="outlined" sx={{ mr: 2 }}>Cancel</Button>
                            <Button onClick={handleUpdateShift} variant="contained" color="success">Update Shift</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {(loading) && (
                <Loader />
            )}
        </>
    );
}

export default EditShifts;
