import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import PhoneInput from 'react-phone-input-2';
// import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import axios from 'axios';

import Swal from 'sweetalert2';
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Loader from '../../../UiUxComponents/Loader/Loader';
import Fileuploadimg from '../../../../assets/Mixed/file-upload.svg';
import { selectToken, selectUser } from '../../../../Redux/selector';
import { getDepartment } from "../../../../utils/Services/subscriberConfigurationServices/departmentsServices"
import { getDesignation } from "../../../../utils/Services/subscriberConfigurationServices/designationServices"
import { getuserDetailsByTabName, updateUser } from '../../../../utils/Services/userServices'
import PdfViewerModal from '../../../UiUxComponents/PdfViewer/PdfViewer';

function PersonalDetails() {
    const [userPersonalDetails, setUserPersonalDetails] = useState()
    const [userInformatioDetails, setUserInformatioDetails] = useState()
    const [departmentDetails, setDepartmentDetails] = useState([])
    const [designationDetails, setDesinationDetails] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [fileInfo, setFileInfo] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const { userId } = useParams();
    // const sigCanvas = useRef(null);
    const tabName = "personalDetails"
    const subscriberId = user?.companyId?._id

    // To get user pesonal Admin details
    const fetchUserPersonalDetails = async () => {
        setLoading(true);
        try {
            const response = await getuserDetailsByTabName(token, tabName, userId);
            setUserPersonalDetails(response?.data?.user)
            setUserInformatioDetails(response?.data?.userInfo[0])
        } catch (err) {
            console.error('Error fetching user personal details:', err);
            Swal.fire({
                icon: 'error',
                title: 'Failed ',
                text: 'Failed to fetch user personal details',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchUserPersonalDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // To fetch Department Details
    const fetchDepartmentDetail = async () => {
        try {
            const response = await getDepartment(token, subscriberId);
            setDepartmentDetails(response?.data || [])
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchDepartmentDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // To fetch Designation Details
    const fetchDesignationDetail = async () => {
        try {
            const response = await getDesignation(token, subscriberId);
            setDesinationDetails(response?.data || [])
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error. Please try again later.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        }
    }
    useEffect(() => {
        fetchDesignationDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle to edit the fields
    const handleEditClick = async () => {
        if (isEditing) {
            await fetchUserPersonalDetails();
        }
        setIsEditing(prevEditing => {
            const newEditingState = !prevEditing;
            if (newEditingState) {
                Swal.fire({
                    icon: 'info',
                    title: 'Editing Enabled',
                    text: 'Now you can edit the fields.',
                    confirmButtonColor: '#1B4965',
                    customClass: {
                        container: 'swal-container',
                    }
                });
            }
            return newEditingState;
        });
    };

    // handle input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserPersonalDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    // handle dropdown change
    const handleDropdownChange = (event) => {
        const { name, value } = event.target;
        setUserPersonalDetails(prevDetails => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    // handle phone number change
    const handlePhoneChange = (value) => {
        setUserPersonalDetails(prevDetails => ({
            ...prevDetails,
            mobileNumber: value,
        }));
    };

    // To Upload a Image
    const { getRootProps: getProfilepicRootProps, getInputProps: getProfilepicInputProps } = useDropzone({
        accept: { 'image/jpeg': [], 'image/png': [], 'image/svg+xml': [] },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file) {
                const fileType = file.type;
                if (fileType.startsWith('image/')) {
                    setUserPersonalDetails((userPersonalDetails) => ({
                        ...userPersonalDetails,
                        profilePic: file,
                    }));
                } else {
                    Swal.fire({
                        title: 'Invalid Image',
                        text: 'Only image files (JPEG, PNG, SVG) are allowed.',
                        icon: 'warning',
                        confirmButtonColor: '#1B4965',
                        customClass: {
                            container: 'swal-container',
                        }
                    });
                }
            }
        },
    });

    // To Clear Signature
    // const clearSignature = () => {
    //     if (sigCanvas.current) {
    //         sigCanvas.current.clear();
    //     }
    //     setUserInformatioDetails((prevDetails) => ({
    //         ...prevDetails,
    //         electronicSignature: null,
    //     }));
    // };

    // To Save Signature
    // const saveSignature = () => {
    //     const base64String = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

    //     const byteString = atob(base64String.split(',')[1]);
    //     const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];

    //     const byteNumbers = new Array(byteString.length);
    //     for (let i = 0; i < byteString.length; i++) {
    //         byteNumbers[i] = byteString.charCodeAt(i);
    //     }
    //     const byteArray = new Uint8Array(byteNumbers);
    //     const blob = new Blob([byteArray], { type: mimeString });
    //     const file = new File([blob], 'signature.png', { type: mimeString });

    //     setUserInformatioDetails(prevState => ({ ...prevState, electronicSignature: file }));
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Signature Saved',
    //         confirmButtonColor: '#1B4965',
    //         customClass: {
    //             container: 'swal-container',
    //         }
    //     });
    // };

    // Handle File Change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setUserPersonalDetails({ ...userPersonalDetails, profileIdCard: file });
    };

    // Handle viewing the PDF
    const handleViewPdf = async () => {
        if (userPersonalDetails?.profileIdCard) {
            try {
                let url;
                if (typeof userPersonalDetails.profileIdCard?.location === 'string') {
                    const response = await axios.get(userPersonalDetails.profileIdCard?.location, {
                        responseType: 'arraybuffer',
                    });
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    url = URL.createObjectURL(blob);
                    setFileInfo({
                        name: userPersonalDetails.profileIdCard.originalname,
                        size: userPersonalDetails.profileIdCard.size
                    })
                } else if (userPersonalDetails.profileIdCard instanceof File) {
                    url = URL.createObjectURL(userPersonalDetails.profileIdCard);
                    setFileInfo({
                        name: userPersonalDetails.profileIdCard.name,
                        size: userPersonalDetails.profileIdCard.size
                    })
                } else {
                    throw new Error('Invalid profileIdCard type');
                }

                setPdfUrl(url);
                setIsModalOpen(true);
            } catch (error) {
                console.error('Error handling PDF:', error);
            }
        }
    };

    // Dropdown for Department
    const selectedDepartmentId = departmentDetails?.find(department => department.departmentName === userPersonalDetails?.department?.name)?._id || '';
    const handleSelectChange = (e) => {
        const selectedId = e.target.value;
        const selectedDepartment = departmentDetails?.find(department => department._id === selectedId);
        setUserPersonalDetails({
            ...userPersonalDetails,
            department: {
                _id: selectedId,
                name: selectedDepartment?.departmentName || ''
            }
        });
    };

    // Dropdown for Designation
    const selectedDesignationId = designationDetails?.find(designation => designation.designationName === userPersonalDetails?.designation?.name)?._id || '';
    const handleSelectChange2 = (e) => {
        const selectedId = e.target.value;
        const selectedDesignation = designationDetails?.find(designation => designation._id === selectedId);
        setUserPersonalDetails({
            ...userPersonalDetails,
            designation: {
                _id: selectedId,
                name: selectedDesignation?.designationName || ''
            }
        });
    };

    // Hanlde Update
    const handleUpdate = async () => {
        setLoading(true);
        try {
            const companyDetails = user?.companyId
            const updatedData = { userPersonalDetails, userInformatioDetails, companyDetails }
            await updateUser(token, userId, tabName, updatedData);
            Swal.fire({
                icon: 'success',
                title: 'Personal Details Updated',
                text: 'The personal details have been successfully updated.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Update Failed',
                text: 'There was an error updating the personal details. Please try again.',
                confirmButtonColor: '#1B4965',
                customClass: {
                    container: 'swal-container',
                }
            });
            console.error('Error updating personal details:', err);
        } finally {
            setLoading(false);
            setIsEditing(false);
            fetchUserPersonalDetails();
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setPdfUrl('');
    };

    return (
        <span>
            {isModalOpen &&
                <PdfViewerModal isOpen={isModalOpen} onRequestClose={handleCloseModal} url={pdfUrl} fileInfo={fileInfo} />
            }
            <Container style={{ padding: '20px', height: '70vh', overflow: 'auto' }}>
                <Grid container spacing={2} justifyContent="flex-end" sx={{ marginBottom: '20px' }}>
                    <Grid item>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#5A9BD5',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#5A9BD5',
                                    opacity: 1
                                }
                            }}
                            endIcon={<EditIcon />}
                            onClick={handleEditClick}>{isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                    </Grid>
                    <Grid item>
                        {isEditing && (
                            <Button variant="outlined"
                                sx={{
                                    backgroundColor: '#1B4965',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#1B4965',
                                        opacity: 1
                                    }
                                }}
                                endIcon={<RestartAltIcon />}
                                onClick={() => {
                                    if (isEditing) {
                                        handleUpdate();
                                    }
                                }}
                            > Update
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                        <Grid item xs={12}>
                            <TextField required id='fullName' name='fullame' label="Full Name" variant='outlined' fullWidth className='TextField' margin='dense'
                                value={userPersonalDetails?.fullName || ''} disabled
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField required id="userName" name="userName" label="User Name" variant="outlined" fullWidth className='TextField' margin="dense"
                                value={userPersonalDetails?.userName || ''} disabled
                                sx={{
                                    '& .MuiInputLabel-root': { fontSize: '14px' },
                                    '& .MuiInputBase-root': { fontSize: '14px' },
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <TextField required id="firstName" name="firstName" label="First Name" variant="outlined" fullWidth className='TextField' margin="dense"
                                    onChange={handleInputChange}
                                    value={userPersonalDetails?.firstName || ''} disabled={!isEditing}
                                    sx={{
                                        '& .MuiInputLabel-root': { fontSize: '14px' },
                                        '& .MuiInputBase-root': { fontSize: '14px' },
                                        width: '100%'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required id="lastName" name="lastName" label="Last Name" variant="outlined" fullWidth className='TextField' margin="dense" onChange={handleInputChange}
                                    value={userPersonalDetails?.lastName || ''} disabled={!isEditing}
                                    sx={{
                                        '& .MuiInputLabel-root': { fontSize: '14px' },
                                        '& .MuiInputBase-root': { fontSize: '14px' },
                                        width: '100%'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        {(userPersonalDetails?.profilePic) ? (
                            <>
                                {userPersonalDetails?.profilePic?.originalname ?
                                    <>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ height: '180px', width: '180px', textAlign: 'center', borderRadius: '50%', overflow: 'hidden' }}>
                                                <img alt="Logo" src={userPersonalDetails?.profilePic?.location} style={{ height: '100%', width: '100%', objectFit: 'fill' }} />
                                            </div>
                                            {isEditing && (
                                                <div style={{ zIndex: 97, display: 'flex', justifyContent: 'flex-end', marginTop: '-150px' }}>
                                                    <IconButton onClick={() => setUserPersonalDetails((prevDetails) => ({ ...prevDetails, profilePic: null }))}>
                                                        <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    </> :
                                    userPersonalDetails?.profilePic.name ?
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ height: '180px', width: '180px', textAlign: 'center', borderRadius: '50%', overflow: 'hidden' }}>
                                                <img alt="Logo" src={typeof userPersonalDetails?.profilePic === 'string' ? userPersonalDetails?.profilePic : URL.createObjectURL(userPersonalDetails?.profilePic)} style={{ height: '100%', width: '100%', objectFit: 'fill' }} />
                                            </div>
                                            <div style={{ zIndex: 97, display: 'flex', justifyContent: 'flex-end', marginTop: '-150px' }}>
                                                <IconButton onClick={() => setUserPersonalDetails((prevDetails) => ({ ...prevDetails, profilePic: null }))}>
                                                    <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                                </IconButton>
                                            </div>
                                        </div> : null
                                }
                            </>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div {...getProfilepicRootProps()} style={{ height: '180px', width: '180px', textAlign: 'center', border: '1px dashed #1B4965', borderRadius: '50%', cursor: 'pointer', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <input {...getProfilepicInputProps()} />
                                    <img src={Fileuploadimg} alt="" />
                                    <p style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Click (or) Drag and Drop your Photo Here.. </p>
                                </div>
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField required id="workEmail" name="workEmail" label="Work Email" variant="outlined" fullWidth margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={userPersonalDetails?.workEmail || ''} disabled={!isEditing}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField required id="personalEmail" name="personalEmail" label="Personal Email" variant="outlined" fullWidth margin="dense" InputProps={{ readOnly: !isEditing }} onChange={handleInputChange} value={userPersonalDetails?.personalEmail || ''} disabled={!isEditing}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <FormControl variant='outlined' required fullWidth margin='dense'>
                            <InputLabel id="user-role">Role</InputLabel>
                            <Select labelId='user-role' id='role' name='role' label="Role" margin="dense"
                                disabled={!(isEditing && user?.role === "Company-super-admin")}
                                value={userPersonalDetails?.role || ''}
                                onChange={handleDropdownChange}
                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                <MenuItem value="Company-super-admin">Super Admin</MenuItem>
                                <MenuItem value="user">user</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneInput country={'in'} containerStyle={{ width: '100%' }} inputStyle={{ width: '100%', fontSize: '14px', height: '50px' }} buttonStyle={{ backgroundColor: 'white' }}
                            disabled={!isEditing}
                            value={userPersonalDetails?.mobileNumber || ''}
                            onChange={handlePhoneChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        {isEditing ? (
                            <FormControl variant='outlined' required fullWidth margin='dense'>
                                <InputLabel id="department">Department</InputLabel>
                                <Select labelId='department' id='department' label="Department" margin="dense"
                                    value={selectedDepartmentId}
                                    onChange={handleSelectChange}
                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                                >
                                    {departmentDetails && departmentDetails.length > 0 ? (
                                        departmentDetails.map((department) => (
                                            <MenuItem key={department._id} value={department._id}>
                                                {department.departmentName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            No Departments
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField id="department" name="department" label="Department" variant="outlined" fullWidth margin="dense" disabled={!isEditing}
                                onChange={handleInputChange}
                                value={userPersonalDetails?.department?.name || ''}
                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {isEditing ? (
                            <FormControl variant='outlined' required fullWidth margin='dense'>
                                <InputLabel id="designation">Designation</InputLabel>
                                <Select labelId='designation' id='designation' label="Designation" margin="dense"
                                    value={selectedDesignationId}
                                    onChange={handleSelectChange2}
                                    sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                                >
                                    {designationDetails && designationDetails.length > 0 ? (
                                        designationDetails?.map((designation) => (
                                            <MenuItem key={designation._id} value={designation._id}>
                                                {designation.designationName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            No Designations
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField id="designation" name="designation" label="Designation" variant="outlined" fullWidth margin="dense" disabled={!isEditing}
                                onChange={handleInputChange}
                                value={userPersonalDetails?.designation?.name || ''}
                                sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <FormControl variant='outlined' required fullWidth margin='dense'>
                            <InputLabel id="gender">Gender</InputLabel>
                            <Select labelId='gender' id='gender' label="Gender" name='gender' margin="dense" disabled={!isEditing} value={userInformatioDetails?.Gender || ''} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, Gender: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="others">Others</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant='outlined' required fullWidth margin='dense'>
                            <InputLabel id="blood-group">Blood Group</InputLabel>
                            <Select labelId='blood-group' id='blood-group' label="Blood Group" name='bloodGroup' disabled={!isEditing} value={userInformatioDetails?.bloodGroup || ''} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, bloodGroup: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}>
                                <MenuItem value="A+">A+</MenuItem>
                                <MenuItem value="A-">A-</MenuItem>
                                <MenuItem value="B+">B+</MenuItem>
                                <MenuItem value="B-">B-</MenuItem>
                                <MenuItem value="AB+">AB+</MenuItem>
                                <MenuItem value="AB-">AB-</MenuItem>
                                <MenuItem value="O+">O+</MenuItem>
                                <MenuItem value="O-">O-</MenuItem>
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="date-of-birth" label="Date of Birth" type="date" name="dateOfBirth" fullWidth margin="dense" variant="outlined"
                            value={userInformatioDetails?.dateOfBirth ? userInformatioDetails.dateOfBirth.split('T')[0] : ''}
                            onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, dateOfBirth: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                            disabled={!isEditing}
                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' } }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <TextField id="emergencyContactName" name="emergencyContactName" label="Emergency Contact Name" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, emergencyContactName: e.target.value })} value={userInformatioDetails?.emergencyContactName || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="emergencyContactNumber" name="emergencyContactNumber" label="Emergency Contact Number" variant="outlined" fullWidth margin="dense" disabled={!isEditing}
                            InputProps={{
                                readOnly: !isEditing,
                                inputProps: {
                                    type: 'number',
                                    min: '0',
                                    pattern: '[0-9]*',
                                }
                            }}
                            onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, emergencyContactNumber: e.target.value })}
                            value={userInformatioDetails?.emergencyContactNumber || ''}
                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="emergencyContactRelation" name="emergencyContactRelation" label="Emergency Contact Relation" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, emergencyContactRelation: e.target.value })} value={userInformatioDetails?.emergencyContactRelation || ''}
                            sx={{
                                '& .MuiInputLabel-root': { fontSize: '14px' },
                                '& .MuiInputBase-root': { fontSize: '14px' },
                                width: '100%'
                            }}
                        />
                    </Grid>
                </Grid>
                <TextField id="userAddress" name="userAddress" label="Address Lane" variant="outlined" fullWidth className='TextField' margin="dense" disabled={!isEditing} value={userInformatioDetails?.userAddress || ''} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, userAddress: e.target.value })} sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }} />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <TextField id="city" name="city" label="City" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, city: e.target.value })} value={userInformatioDetails?.city || ''}
                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="state" name="state" label="State" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, state: e.target.value })} value={userInformatioDetails?.state || ''}
                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="country" name="country" label="Country" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, country: e.target.value })} value={userInformatioDetails?.country || ''}
                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="timezone" name="timezone" label="Timezone" variant="outlined" fullWidth margin="dense" disabled={!isEditing} onChange={(e) => setUserInformatioDetails({ ...userInformatioDetails, timezone: e.target.value })} value={userInformatioDetails?.timezone || ''}
                            sx={{ '& .MuiInputLabel-root': { fontSize: '14px' }, '& .MuiInputBase-root': { fontSize: '14px' }, width: '100%' }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    {/* <Grid item xs={8}>
                        <div style={{ border: '1px solid #ccc', marginTop: '20px', width: '96%', padding: '10px' }}>
                            {userInformatioDetails?.electronicSignature?.location ? (
                                <img
                                    src={userInformatioDetails.electronicSignature.location}
                                    alt="Electronic Signature"
                                    style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                                />
                            ) : (
                                <>
                                    <SignatureCanvas
                                        ref={sigCanvas}
                                        penColor="black"
                                        canvasProps={{ width: 500, height: 180, className: 'sigCanvas' }}
                                    />
                                </>
                            )}
                            {isEditing && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button onClick={clearSignature} variant="outlined" sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Clear</Button>
                                    <Button onClick={saveSignature} variant="outlined" sx={{ color: '#1B4965', border: '1px solid #1B4965' }}>Save</Button>
                                </div>
                            )}
                        </div>
                    </Grid> */}
                    <Grid item xs={4} sx={{ marginTop: '20px' }}>
                        {userPersonalDetails?.profileIdCard ? (
                            <div style={{ display: 'flex', }}>
                                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }} onClick={handleViewPdf}>View ID Card</Button>
                                {isEditing && (
                                    <div>
                                        <IconButton onClick={() => setUserPersonalDetails((prevDetails) => ({ ...prevDetails, profileIdCard: null }))}>
                                            <DeleteForeverIcon sx={{ fontSize: '22px', cursor: 'pointer', }} />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <Grid item>
                                    <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />} sx={{ background: 'linear-gradient(90deg, #1B4965 63.83%, #051925 82.97%)', backgroundSize: '200%' }}>
                                        Upload ID card
                                        <input type="file" accept="application/pdf" style={{ display: 'none' }} onChange={handleFileChange} />
                                    </Button>
                                </Grid>
                                <Grid>
                                    {userPersonalDetails?.uploadedFile && <span>{userPersonalDetails?.uploadedFile?.name}</span>}
                                </Grid>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Container>
            {(loading) && (
                <Loader />
            )}
        </span>
    )
}

export default PersonalDetails

const Container = styled.div`
    padding: 20px;
    height: 70vh;
`;
